import React, { useState } from 'react';
import axios from 'axios';
import { setupAxiosCSRF } from '../../helpers';
import AnswerEditor from './AnswerEditor';

const QuestionEditor = ({ question, showAnswer }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(question.content);
  const [explanation, setExplanation] = useState(question.explanation || '');
  const [answers, setAnswers] = useState(question.answers);
  const [errors, setErrors] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // State for saving indicator
  const [saveSuccess, setSaveSuccess] = useState(false); // State for success message and visual feedback

  const handleEditClick = () => {
    setIsEditing(true);
    setSaveSuccess(false); // Reset success message when editing starts
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setContent(question.content);
    setExplanation(question.explanation || '');
    setAnswers(question.answers);
    setSaveSuccess(false); // Reset success message when editing is canceled
  };

  const handleAnswerChange = (id, updatedAnswer) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.id === id ? { ...answer, ...updatedAnswer } : answer
      )
    );
  };

  const handleSaveClick = async () => {
    try {
      setIsSaving(true); // Start the saving process
      setupAxiosCSRF(); // Setup CSRF token for Axios
      const response = await axios.patch(`/questions/${question.id}`, {
        question: {
          content,
          explanation,
          answers_attributes: answers.map(({ id, content, is_correct }) => ({
            id,
            content,
            is_correct,
          })),
        },
      });

      if (response.status === 200) {
        setContent(response.data.content);
        setExplanation(response.data.explanation);
        setAnswers(response.data.answers);
        setIsEditing(false);
        setErrors(null);
        setSaveSuccess(true); // Indicate success
        setTimeout(() => setSaveSuccess(false), 3000); // Hide success visual feedback after 3 seconds
      }
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    } finally {
      setIsSaving(false); // End the saving process
    }
  };

  return (
    <div className={`question-editor mb-4 bg-black text-white ${saveSuccess ? 'border-success' : ''}`} style={{ transition: 'border-color 0.3s ease' }}>
      {isEditing ? (
        <div className="border p-3 rounded">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="form-control bg-black text-white"
              rows="2"
            />
          </div>
          <div className="mt-3">
            {answers.map((answer, index) => (
              <div key={answer.id} className="mb-2">
                <AnswerEditor
                  key={answer.id}
                  answer={answer}
                  onAnswerChange={handleAnswerChange}
                  label={String.fromCharCode(65 + index)}
                />
              </div>
            ))}
          </div>
          <div className="mt-3">
            <label><strong>Explanation:</strong></label>
            <textarea
              value={explanation}
              onChange={(e) => setExplanation(e.target.value)}
              className="form-control bg-black text-white"
              rows="3"
            />
          </div>
          {errors && (
            <div className="text-danger mt-2">
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )}
          <div className="d-flex justify-content-end mt-3">
            <button
              onClick={handleSaveClick}
              className="btn btn-success btn-sm mx-2"
              disabled={isSaving} // Disable the button while saving
            >
              {isSaving ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                'Save'
              )}
            </button>
            <button
              onClick={handleCancelClick}
              className="btn btn-secondary btn-sm"
              disabled={isSaving} // Disable the button while saving
            >
              Cancel
            </button>
          </div>
          {saveSuccess && (
            <div className="text-success mt-2">
              <strong>Question saved successfully!</strong>
            </div>
          )}
        </div>
      ) : (
        <div className={`border p-3 rounded position-relative ${saveSuccess ? 'border-success' : ''}`} style={{ transition: 'border-color 0.3s ease' }}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{question.content}</p>
            <div>
              {saveSuccess && (
                <i className="fas fa-check text-success mx-2"></i>
              )}
              <button
                onClick={handleEditClick}
                className="btn btn-link text-success p-0 mx-2"
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
            </div>
          </div>
          <ul className="list-unstyled">
            {answers.map((answer, index) => (
              <li
                key={answer.id}
                className={`border p-2 rounded mb-2 ${
                  showAnswer && answer.is_correct ? 'bg-success text-white' : ''
                }`}
              >
                <strong>{String.fromCharCode(65 + index)}. </strong>
                {answer.content}
              </li>
            ))}
          </ul>
          {showAnswer && explanation && (
            <div className="mt-3 p-2 bg-info rounded border text-black">
              <strong>Explanation:</strong>
              <p className="mb-0">{explanation}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionEditor;
