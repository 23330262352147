import React, { useEffect, useState } from "react";
import axios from 'axios';
import { setupAxiosCSRF } from '../../helpers';

const QuizTakingInterface = ({ quiz, questions, user }) => {
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
  const totalTime = questions.reduce((total, question) => total + question.time_limit, 0);
  const [timeLeft, setTimeLeft] = useState(totalTime);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answerSubmissionStatus, setAnswerSubmissionStatus] = useState({});
  const [cheatingWarning, setCheatingWarning] = useState(false);
  const [cheatingCount, setCheatingCount] = useState(0)
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const totalQuestions = questions.length;
  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;
  const DISPLAY_RADIUS = 5;
  const startQuestionIndex = Math.max(0, currentQuestionIndex - DISPLAY_RADIUS);
  const endQuestionIndex = Math.min(questions.length, currentQuestionIndex + DISPLAY_RADIUS + 1);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 3000;

  const handleAnswerSelection = (questionId, answerId) => {
    setSelectedAnswer(prevState => ({
      ...prevState,
      [questionId]: answerId
    }));

    setAnswerSubmissionStatus(prevStatus => ({
      ...prevStatus,
      [questionId]: 'saving'
    }));

    saveAnswerToServer(questionId, answerId);
  };

  const saveAnswerToServer = (questionId, answerId, retryCount = 0) => {
    setupAxiosCSRF();
    axios.post(`/quizzes/${quiz.id}/submit_answer`, {
      question_id: questionId,
      answer_id: answerId,
    })
    .then(response => {
      console.log("Answer saved successfully.", response.data);
      setAnswerSubmissionStatus(prevStatus => ({
        ...prevStatus,
        [questionId]: 'saved'
      }));
    })
    .catch(error => {
      console.log("Error saving answer.", error);

      // If the maximum retry count hasn't been reached, schedule a retry
      if (retryCount < MAX_RETRIES) {
        console.log(`Retrying in ${RETRY_DELAY / 1000} seconds...`);
        setTimeout(() => {
          saveAnswerToServer(questionId, answerId, retryCount + 1);
        }, RETRY_DELAY);
      } else {
        console.log("Max retries reached. Giving up.");
        setAnswerSubmissionStatus(prevStatus => ({
          ...prevStatus,
          [questionId]: 'error'
        }));
      }
    });
  };

  const handleQuestionJump = (questionIndex) => {
    if (questionIndex >= 0 && questionIndex < questions.length) {
      setCurrentQuestionIndex(questionIndex);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  useEffect(() => {

    const handleContextMenu = (e) => {
      e.preventDefault();
      setCheatingWarning(true);
      setCheatingCount(prevCount => prevCount + 1);
    }

    const handleCopy = (e) => {
      setCheatingWarning(true);
      setCheatingCount(prevCount => prevCount + 1);
    }

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('copy', handleCopy);
    }
  }, []);

  useEffect(() => {
    if (cheatingCount >= 5) {
      handleDisqualification();
    }
  }, [cheatingCount]);

  useEffect(() => {
    let countDownInterval;
    clearInterval(countDownInterval);
    countDownInterval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(countDownInterval);
          handleSubmit();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(countDownInterval);
    };
  }, [currentQuestionIndex, questions]);

  useEffect(() => {
    // Function to shuffle answers
    function shuffle(array) {
      let currentIndex = array.length, temporaryValue, randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }

    // Shuffle answers for the current question
    if (questions && questions.length > 0 && questions[currentQuestionIndex]) {
      const shuffled = shuffle([...questions[currentQuestionIndex].answers]);
      setShuffledAnswers(shuffled);
    }
  }, [questions, currentQuestionIndex]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isQuizSubmitted) {
        e.preventDefault();
        e.returnValue = "Are you sure you want to leave? Your quiz will be submitted automatically.";
        handleDisqualification();
      }
      return e.returnValue;
    };

    if (!isQuizSubmitted) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isQuizSubmitted]);

  const handleSubmit = () => {
    setIsSubmitting(true);

    setupAxiosCSRF();
    axios.post(`/quizzes/${quiz.id}/finalize_submission`)
      .then(response => {
        console.log("Quiz submitted successfully.", response.data);
        setIsSubmitting(false);
        window.location.href = `/quizzes/${quiz.id}/result`;
      })
      .catch(error => {
        console.log("Error submitting answers.", error);
    });
    setIsQuizSubmitted(true);
  };

  const handleDisqualification = () => {
    setIsSubmitting(true);

    setupAxiosCSRF();
    axios.post(`/quizzes/${quiz.id}/disqualified`)
      .then(response => {
        console.log("Quiz disqualified successfully.", response.data);
        setIsSubmitting(false);
        alert('You have been disqualified and logged out.');
        window.location.href = '/users/sign_in';
      })
      .catch(error => {
        console.log("Error disqualifying quiz.", error);
    });
    setIsQuizSubmitted(true);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h1>{quiz.title} - {questions.length} Questions</h1>
          <div className='mt-2 alert alert-info'>
           <h5><i className='fa-solid fa-user me-2'></i>Playing as: <span className="text-capitalize">{user.full_name}</span>
           </h5>
           <h5><i className='fa-solid fa-id-card me-2'></i>Reference: <span> {user.uid}</span></h5>
          </div>
          <p>{quiz.description}</p>
          <div className="my-3 d-flex justify-content-center">
            <span className={`badge ${timeLeft <= 10 ? 'bg-danger' : 'bg-success'} p-3`} style={{ fontSize: '2rem' }}>
              {formatTime(timeLeft)}
            </span>
          </div>
          <div className="card border-dark mb-3">
            <div className="card-header fw-bold bg-dark text-white fs-5">
             {currentQuestionIndex + 1}. {questions[currentQuestionIndex].content}
             <small className="text-danger d-block mt-1">Once you select an answer, it's final. Choose wisely!</small>
              {cheatingWarning && (
                <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setCheatingWarning(false)}></button>
                  <i className="fas fa-exclamation-triangle"></i> Cheating is not allowed. You will be disqualified if you attempt to cheat. <br />
                  {cheatingCount <= 5 && <span> <i className="far fa-hourglass"></i> You have {5 - cheatingCount} warnings left before the quiz auto submit.</span>}
                </div>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                {shuffledAnswers.map(answer => (
                  <div className="col-md-6 mb-3" key={answer.id}>
                    <div className={`card h-100 ${selectedAnswer[questions[currentQuestionIndex].id] === answer.id ? 'bg-dark border-dark text-white' : 'bg-light border-secondary'}`}>
                      <div className="card-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`question_${questions[currentQuestionIndex].id}`}
                            id={`answer_${answer.id}`}
                            value={answer.id}
                            checked={selectedAnswer[questions[currentQuestionIndex].id] === answer.id}
                            onChange={() => handleAnswerSelection(questions[currentQuestionIndex].id, answer.id)}
                            disabled={!!selectedAnswer[questions[currentQuestionIndex].id]}
                          />
                        <label className="form-check-label fw-bold" htmlFor={`answer_${answer.id}`}>
                            {answer.content}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-3">
                {answerSubmissionStatus[questions[currentQuestionIndex].id] === 'saving' && (
                  <span className="text-warning"><i className="fas fa-spinner fa-spin"></i> Submitting answer...</span>
                )}
                {answerSubmissionStatus[questions[currentQuestionIndex].id] === 'saved' && (
                  <span className="text-success"><i className="fas fa-check"></i> Answer submitted successfully</span>
                )}
                {answerSubmissionStatus[questions[currentQuestionIndex].id] === 'error' && (
                  <span className="text-danger"><i className="fas fa-times"></i> Error submitting answer.</span>
                )}
                {answerSubmissionStatus[questions[currentQuestionIndex].id] === 'not_allowed' && (
                  <span className="text-danger"><i className="fas fa-times"></i> Answer change not allowed.</span>
                )}
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="progress">
              <div
                className="progress-bar progress-bar progress-bar-striped bg-success progress-bar-animated"
                aria-label="Question progress"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {currentQuestionIndex + 1} / {totalQuestions}
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap justify-content-center mb-3">
            {questions.slice(startQuestionIndex, endQuestionIndex).map((q, index) => {
              const absoluteIndex = startQuestionIndex + index;
              const isAnswered = !!selectedAnswer[q.id];
              let buttonClass;

              if (absoluteIndex === currentQuestionIndex) {
                buttonClass = 'btn-success';
              } else if (isAnswered) {
                buttonClass = 'btn-secondary';
              } else {
                buttonClass = 'btn-outline-danger';
              }

              return (
                <button
                  key={absoluteIndex}
                  className={`btn btn-md mx-1 my-1 ${buttonClass} ${isAnswered ? 'disabled' : ''}`}
                  onClick={() => {
                      if (!isAnswered) {
                        handleQuestionJump(absoluteIndex);
                      }
                  }}
                  disabled={isAnswered}
                >
                  {absoluteIndex + 1}
                </button>
              );
            })}
          </div>

          <div className="d-flex justify-content-between mb-3">
            <button
              className="btn btn-info"
              onClick={handlePreviousQuestion}
              disabled={currentQuestionIndex === 0}
            >
              <i className="fas fa-arrow-left"></i> Previous
            </button>
            <button
              className="btn btn-info"
              onClick={handleNextQuestion}
              disabled={currentQuestionIndex === questions.length - 1 || !selectedAnswer[questions[currentQuestionIndex].id]}
            >
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>

          <div className="btn-group">
            {isSubmitting ? (
                <span className="btn btn-secondary disabled">
                  <i className="fas fa-spinner fa-spin"></i> Submitting...
                </span>
            ) : (
              <button
                type="submit"
                className="btn btn-success"
                onClick={handleSubmit}
                disabled={Object.keys(selectedAnswer).length !== questions.length}
              >
                View result
              </button>
            )}
          </div>
        </div>
      </div>
  </div>
  );
};

export default QuizTakingInterface;
